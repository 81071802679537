<template>
  <div>
    员工奖惩
  </div>
</template>

<script>
export default {
  name: "PerEc"
}
</script>

<style scoped>

</style>