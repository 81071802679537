<template>
  <div>
    人事记录统计
  </div>
</template>

<script>
export default {
  name: "StaRecord"
}
</script>

<style scoped>

</style>