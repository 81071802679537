<template>
  <div>
    月末处理
  </div>
</template>

<script>
export default {
  name: "SalMonth"
}
</script>

<style scoped>

</style>