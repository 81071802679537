<template>
  <div>
    人事信息统计
  </div>
</template>

<script>
export default {
  name: "StaPers"
}
</script>

<style scoped>

</style>